.sidebar {
  min-height: 100vh;
  background: #FBED21;
  font-family: $type1;
  padding: 0;
  width: $sidebar-width-lg;
  z-index: 11;
  transition: width $action-transition-duration $action-transition-timing-function,
    background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: width $action-transition-duration $action-transition-timing-function,
    background $action-transition-duration $action-transition-timing-function;
  -moz-transition: width $action-transition-duration $action-transition-timing-function,
    background $action-transition-duration $action-transition-timing-function;
  -ms-transition: width $action-transition-duration $action-transition-timing-function,
    background $action-transition-duration $action-transition-timing-function;
  .sidebar-brand-wrapper {
    width: auto;
    height: $navbar-height;

    .sidebar-brand {
      padding: 2.25rem 0 0.25rem 40px;

      .rtl & {
        padding: 20px 26px 10px 0;
      }

      img {
        width: calc(#{$sidebar-width-lg} - 130px);
        max-width: 100%;
        height: 48px;
        margin: auto;
        vertical-align: middle;
      }
    }

    .brand-logo-mini {
      display: none;
      img {
        width: calc(#{$sidebar-width-icon} - 50px);
        max-width: 100%;
        height: 35px;
        margin: auto;
      }
    }
  }
  .nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;
    margin-bottom: 60px;
    margin-top:30px;
    .nav-item {
      padding: 0 $sidebar-menu-padding-x;
      @include transition-duration(0.25s);
      transition-property: background;
      -webkit-transition-property: background;
      .collapse {
        z-index: 999;
      }

      .nav-link {
        @include display-flex;
        @include align-items(center);
        white-space: nowrap;
        padding: $sidebar-menu-padding-y 0 $sidebar-menu-padding-y 0;
        color: $sidebar-light-menu-color;
        transition: all .3s ease-in-out;
        cursor: pointer;

        i {
          color: inherit;
          &.menu-icon {
            font-size: $sidebar-icon-font-size;
            line-height: 1;
            margin-right: 10px;
            .rtl & {
              margin-left: 10px;
              margin-right: 0;
            }
            color: black;
            &:before {
              vertical-align: middle;
            }
          }

          &.menu-arrow {
            font: normal normal normal 24px/1 "Material Design Icons";
            line-height: 1;
            font-size: $sidebar-icon-font-size;
            margin-left: auto;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
            color: $sidebar-light-menu-arrow-color;
            &:before {
              content: "\F415";
              font-size: 16px;
              color: #423a8e;
              opacity: 0.36;
            }
            + .menu-icon {
              margin-left: 0.25rem;
              .rtl & {
                margin-left: 0;
                margin-right: 0.25rem;
              }
            }
          }
        }

        .menu-title {
          color: #141212;
          display: inline-block;
          font-size: $sidebar-menu-font-size;
          line-height: 1;
          vertical-align: middle;
        }

        .badge {
          margin-right: auto;
          margin-left: 1rem;
          .rtl & {
            margin-right: 1rem;
            margin-left: auto;
          }
        }

        &[aria-expanded="true"] {
          .menu-arrow {
            &:before {
              content: "\F374";
            }
          }
        }
        &:hover {
          font-weight: 500;
          color: aqua;
          @include transition-duration(0.3s);
          transform: scale(1.15);
        }
        &.menu-expanded {
          i {
            &.menu-arrow {
              &:before {
                content: "\F374";
                font-size: 16px;
                color: #423a8e;
                opacity: 0.36;
              }
            }
          }
        }
      }

      &.active {
        background: inherit;
        > .nav-link {
          font-weight: 500;
          .menu-title {
            color: inherit;
            font-family: $type1;
            font-weight: 500;
          }
        }
      }
      &.nav-profile {
        .nav-link {
          height: auto;
          line-height: 1;
          border-top: 0;
          padding: 1.25rem 0;
          .nav-profile-image {
            width: 35px;
            height: 35px;
            img {
              width: 35px;
              height: 35px;
              border-radius: 100%;
              border: solid 2px $sidebar-light-menu-border-color;
            }
          }
          .nav-profile-text {
            margin-left: 1rem;
            .rtl & {
              margin-left: auto;
              margin-right: 1rem;
            }
          }
          .nav-profile-badge {
            font-size: 1.125rem;
            margin-left: auto;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
          }
        }
      }
      &.sidebar-actions {
        margin-top: 1rem;
        .nav-link {
          border-top: 0;
          display: block;
          height: auto;
          cursor: pointer;
        }
        &:hover {
          background: initial;
          .nav-link {
            color: initial;
          }
        }
        ul {
          li {
            list-style: none;
            color: $sidebar-light-menu-icon-color;
            font-size: 15px;
            font-weight: 500;
          }
        }
      }
    }

    &:not(.sub-menu) {
      > .nav-item {
        &:hover {
          &:not(.nav-category):not(.nav-profile) {
            > .nav-link {
              color: $sidebar-light-menu-hover-color;
            }
          }
        }
      }
    }
    &.sub-menu {
      margin-bottom: 20px;
      margin-top: 0;
      list-style: none;

      .nav-item {
        padding: 0 0 0 18px;
        .rtl & {
          padding: 0 18px 0 0;
        }
        .nav-link {
          color: $sidebar-light-submenu-color;
          padding: $sidebar-submenu-item-padding;
          position: relative;
          font-size: $sidebar-submenu-font-size;
          line-height: 1;
          height: auto;
          border-top: 0;
          cursor: pointer;
          &:before {
            color: inherit;
            font-size: 0.75rem;
            content: "";
            position: absolute;
            width: 9px;
            height: 9px;
            border: 2px solid $sidebar-light-submenu-color;
            border-radius: 50%;
            margin-left: -1.25rem;
            top: 19px;
            .rtl & {
              left: auto;
              right: 0;
            }
            top: 50%;
            @include transform(translateY(-50%));
            color: lighten($sidebar-light-submenu-color, 10%);
            font-size: 0.75rem;
          }
          &.active {
            color: $sidebar-light-menu-active-color;
            background: transparent;
          }
          &:hover {
            color: $sidebar-light-submenu-hover-color;
          }
        }
        &:hover {
          background: transparent;
        }
      }
    }
  }
}

/* style for off-canvas menu*/
@media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - #{$navbar-height});
    top: $navbar-height;
    bottom: 0;
    overflow: auto;
    right: -$sidebar-width-lg;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    &.active {
      right: 0;
    }
  }
}