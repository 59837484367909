.yellow-btn {
    background-color: #FBED21 !important;
    color: black !important;
    border: 1px solid #FBED21 !important;
    text-transform: uppercase !important;
}

.yellow-btn:hover {
    background-color: #FFF !important;
    border: 1px solid #FBED21 !important;
    color: #FBED21 !important;
}

.edit-icons {
    background-color: #FBED21 !important;
    border: 1px solid #FBED21 !important;
    color: black !important;
    // border: none !important;
    // border-radius: 50%;
}

.edit-icons:hover {
    background-color: #FFF !important;
    border: 1px solid #FBED21 !important;
    color: #FBED21 !important;
}

.form-control::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #5A5A5A;
    opacity: 1;
    /* Firefox */
}

.custom-input-fields .form-control {
    display: block;
    width: 100%;
    height: 2.875rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1;
    color: #495057 !important;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #ebedf2 !important;
    border-radius: 2px;
    background-image: none !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-input-fields .form-control:active {
    border: 1px solid #ced4da;
}

.red-border {
    border: 1px solid red !important;
}

.form-check-input {
    zoom: 1.3;
}

@media (max-width: 1200px) {
    .col-form-label {
        font-size: 14px;
    }
}

.card .card-title {
    font-weight: bold;
}

.multiSelectContainer input {
    font-size: 14px;
}

.artist-register-icon {
    position: absolute;
    top: 5%;
    right: 10%;
    background-color: white;
    padding: 1px;
    border-radius: 50%;
    cursor: pointer;
}

.artist-register-icon-new {
    position: absolute;
    top: 19%;
    right: 10%;
    background-color: white;
    padding: 1px;
    border-radius: 50%;
    cursor: pointer;
}

.artist-register-image {
    position: relative;
    width: 150px;
    height: 150px;
}

.artist-image {
    width: 100%;
    height: 100%;
}

.mypPofileBtn {
    text-transform: uppercase;
    background-color: #FFF;
    color: black !important;
    border: 1px solid rgb(183, 176, 176);
}

.mypPofileBtn:hover {
    background-color: #FBED21 !important;
}

.side_area {
    height: 90vh;

    align-items: center;
}

.bottom_text {
    border-top: 1px solid;
    display: flex;
    justify-content: space-between;
    margin-left: 5%;
    margin-right: 5%;
}

.notification_card {
    border-bottom: 1px solid #acaeb0;
    padding: 2%;
    display: flex;
}

.meta-information {
    display: flex;
    justify-content: space-between;
}