/* Navbar */

.navbar {
  font-family: $type1;
  left: $sidebar-width-lg;
  transition: left $action-transition-duration $action-transition-timing-function,
    width $action-transition-duration $action-transition-timing-function;
  -webkit-transition: left $action-transition-duration $action-transition-timing-function,
    width $action-transition-duration $action-transition-timing-function;
  -moz-transition: left $action-transition-duration $action-transition-timing-function,
    width $action-transition-duration $action-transition-timing-function;
  -ms-transition: left $action-transition-duration $action-transition-timing-function,
    width $action-transition-duration $action-transition-timing-function;

  .navbar-menu-wrapper {
    transition: width $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function;
    color: #FFFFFF;
    padding-left: 15px;
    padding-right: 15px;
    width: calc(100% - #{$sidebar-width-lg});
    height: $navbar-height;
    background: $navbar-bg;
    @media (max-width: 991px) {
      width: 100%;
      padding-left: 11px;
    }

    .navbar-toggler {
      border: 0;
      color: $navbar-menu-color;
      &:not(.navbar-toggler-right) {
        @media (max-width: 991px) {
          display: none;
        }
      }
      i {
        font-size: 1rem;
        &:before {
          width: 34px;
          height: 34px;
          border-radius: 50px;
          background: $navbar-item-bg;
          text-align: center;
          line-height: 2.2;
        }
      }
    }

    .navbar-text {
      font-size: $default-font-size;
    }

    .navbar-nav {
      @include flex-direction(row);
      @include align-items(stretch);
      .nav-item {
        @include display-flex();
        @include align-items(center);
        padding-left: 1rem;
        padding-right: 1rem;
        @media (max-width: 767px) {
          padding-right: 0.5rem;
          padding-left: 0.5rem;
        }
        border-left: 1px solid $navbar-item-bg;
        .nav-link {
          color: $navbar-menu-color;
          font-size: $navbar-font-size;
          i {
            font-size: $navbar-icon-font-size;
            color: $navbar-menu-color;
          }
          .nav-profile-img {
            height: 34px;
            width: 34px;
            border-radius: 50%;
            margin-right: 10px;
          }
        }
        .dropdown {
          .dropdown-toggle {
            padding: 0;
            font-weight: normal;
            &:after {
              content: "\F140";
              display: inline-block;
              font: normal normal normal 24px/1 "Material Design Icons";
              font-size: inherit;
              text-rendering: auto;
              line-height: inherit;
              -webkit-font-smoothing: antialiased;
              border: 0;
              vertical-align: inherit;
            }
            i {
              font-size: 1rem;
              line-height: 2;
              &:before {
                width: 34px;
                height: 34px;
                border-radius: 50px;
                background: $navbar-item-bg;
                text-align: center;
                line-height: 2.2;
              }
            }
            &.bg-transparent {
              background: transparent;
              border: none;
            }
          }
          .count-indicator {
            position: relative;
            border-radius: 100%;
            text-align: center;
            margin-right: 0;
            i {
              font-size: 0.9375rem;
              margin-right: 0;
              vertical-align: middle;
            }
            .count {
              position: absolute;
              left: 20px;
              width: 14px;
              height: 14px;
              border-radius: 100%;
              top: -4px;
              font-size: 0.5875rem;
              color: $white;
            }
            &:after {
              display: none;
            }
            .count-varient1 {
              background: theme-color(warning);
            }
            .count-varient2 {
              background: theme-color(success);
            }
          }
          i {
            margin-right: 0.5rem;
            vertical-align: middle;
            .rtl & {
              margin-left: 0;
              margin-right: 0;
            }
          }
          .navbar-dropdown {
            font-size: 0.9rem;
            margin-top: 1rem;
            position: absolute;
            top: $navbar-height;
            cursor: pointer;
            left: auto;
            right: auto;
            min-width: 100%;
            -webkit-box-shadow: 0px 0px 13px -3px rgba(0, 0, 0, 0.1);
            -moz-box-shadow: 0px 0px 13px -3px rgba(0, 0, 0, 0.1);
            box-shadow: 0px 0px 13px -3px rgba(0, 0, 0, 0.1);
            @include border-radius(4px);
            @media (max-width: 991px) {
              right: -85px;
            }
            @extend .dropdownAnimation;
            border: 1px solid rgba(182, 182, 182, 0.1);
            padding: 0;
            @include border-radius(0);
            overflow: hidden;
            .rtl & {
              left: auto;
              right: 0;
              min-width:11rem;
              text-align: right;
            }
            .navbar-nav-right & {
              right: 0;
              left: auto;
              .rtl & {
                right: auto;
                left: 0;
              }
            }
            &.dropdown-left {
              left: 0;
              right: auto;
            }
            .dropdown-item {
              @extend .d-flex;
              @extend .align-items-center;
              margin-bottom: 0;
              padding: 11px 30px;
              line-height: 1.4;
              .rtl & {
                padding: 11px 30px;
              }

              i {
                font-size: 17px;
                .rtl & {
                  margin-left: .5rem;
                  margin-right: 0;
                }
              }

              .ellipsis {
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .preview-icon {
                width: 40px;
                height: 40px;
              }
              .small-text {
                font-size: 0.75rem;
              }
              &:nth-child(2n) {
                background: $navbar-item-light-bg;
              }
              .badge {
                margin-bottom: 0.5rem;
              }
            }

            .dropdown-divider {
              margin: 0;
            }
            &.navbar-dropdown-large {
              width: 300px;
              @media (max-width: 767px) {
                width: 100%;
              }
              .dropdown-item {
                white-space: normal;
                &:nth-child(2n) {
                  background: $navbar-item-light-bg;
                }
              }
            }
          }
          @media (max-width: 767px) {
            position: static;
            .navbar-dropdown,
            .navbar-dropdown-large {
              width: 100%;
              margin: auto;
              left: 0px;
              right: 0px;
              width: 100%;
              top: 69px;
              @media (max-width: 767px) {
                margin-top: 20px;
              }
            }
          }
        }
        &.nav-search {
          .input-group {
            width: 260px;
            .input-group-append {
              background: transparent;
            }
            .form-control {
              background: $navbar-item-bg;
              border: 0;
              color: $white;
              padding: 0.5rem 1rem;
              border-top-left-radius: 50px;
              border-bottom-left-radius: 50px;
              height: auto;
              .rtl & {
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
              }
            }
            .input-group-text {
              background: $navbar-item-bg;
              border: 0;
              color: $white;
              padding: 0.5rem 1rem;
              border-top-right-radius: 50px;
              border-bottom-right-radius: 50px;
              .rtl & {
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                border-top-left-radius: 50px;
                border-bottom-left-radius: 50px;
              }
            }
          }
        }
        &.nav-profile {
          .nav-link {
            .profile-name {
              @media (max-width: 767px) {
                display: none;
              }
            }
          }
        }
        @media (max-width: 576px) {
          border: none;
        }
      }
      &.navbar-nav-right {
        @media (min-width: 992px) {
          margin-left: auto;
          .nav-item {
            .dropdown {
              .dropdown-toggle {
                &:after {
                  content: "\F140";
                  display: inline-block;
                  font: normal normal normal 24px/1 "Material Design Icons";
                  font-size: inherit;
                  text-rendering: auto;
                  line-height: inherit;
                  -webkit-font-smoothing: antialiased;
                  border: 0;
                  vertical-align: inherit;
                }
              }
            }
          }
          .rtl & {
            margin-left: 0;
            margin-right: auto;
          }
        }
      }
    }
  }
  //navbar color variations
  &.navbar-dark {
    .count-varient1 {
      background: theme-color(warning);
    }
    .count-varient2 {
      background: theme-color(success);
    }
  }

  &.navbar-light {
    .navbar-brand {
      color: $black;
    }
    .navbar-menu-wrapper {
      background: $white;
      .navbar-nav {
        .nav-item {
          border-color: $light-color-icon-bg;
          .nav-link {
            color: $black;
          }
          &.dropdown {
            .dropdown-toggle {
              i {
                &:before {
                  background: $light-color-icon-bg;
                }
              }
            }
          }
          &.nav-search {
            .input-group {
              .form-control,
              .input-group-text {
                background: $light-color-icon-bg;
              }
              .form-control {
                @include input-placeholder {
                  color: $black;
                }
              }
              .input-group-text {
                color: $black;
              }
            }
          }
        }
      }
      .navbar-toggler {
        i:before {
          background: $light-color-icon-bg;
          color: $black;
        }
      }
    }
    @extend .navbar-dark;
  }
  @media (max-width: 991px) {
    left: 0;
    height: $navbar-height;
    padding: 0;
  }
  @media (max-width: 768px) {
    padding-left: 25px;
    padding-right: 13px;
    background: $black;
    padding-bottom: 0;
    padding-top: 0;
  }
}
@media (max-width:991px) {
  .navbar {
    flex-direction: row;
    .navbar-brand-wrapper {
      width: 75px;
      .navbar-brand {
        &.brand-logo {
          display: none;
        }
        &.brand-logo-mini {
          display: inline-block;
        }
      }
    }
  }

  .navbar-collapse {
    display: flex;
    margin-top: 0.5rem;
  }
}

@media (max-width:480px) {
  .navbar {
    .navbar-brand-wrapper {
      width: 55px;
      .brand-logo-mini{
        padding-top: 0px;
      }
    }
  }
} 